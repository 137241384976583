import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSettingsContext } from '../../components/settings';
import useResponsive from '../../hooks/useResponsive';
import { isAuthorizedList } from '../../utils/isAuthorisedSidebarItem';
import Main from './Main';
import Header from './header';
import NavHorizontal from './nav/NavHorizontal';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import navConfig from './nav/config-navigation';
// ----------------------------------------------------------------------
export default function DashboardLayout() {
    const { themeLayout } = useSettingsContext();
    const isDesktop = useResponsive('up', 'lg');
    const [open, setOpen] = useState(false);
    const isNavHorizontal = themeLayout === 'horizontal';
    const isNavMini = themeLayout === 'mini';
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const authorizedItems = useMemo(() => {
        if (!navConfig || !navConfig.length)
            return [];
        return navConfig.flatMap((group) => isAuthorizedList(group?.items) || []);
    }, []);
    const renderNavVertical = _jsx(NavVertical, { openNav: open, onCloseNav: handleClose });
    if (isNavHorizontal) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), isDesktop ? _jsx(NavHorizontal, {}) : renderNavVertical, _jsx(Main, { children: _jsx(Outlet, {}) })] }));
    }
    if (isNavMini) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, { sx: {
                        display: { lg: 'flex' },
                        minHeight: { lg: 1 },
                    }, children: [isDesktop ? _jsx(NavMini, {}) : renderNavVertical, _jsx(Main, { children: _jsx(Outlet, {}) })] })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, { sx: {
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                }, children: [renderNavVertical, _jsx(Main, { children: _jsx(Outlet, {}) })] })] }));
}
